import { httpAuth } from "../config/service";

export const dataProvider = {
  getList: (path, params) => httpAuth().get(`/${path}`, {params}).then(res=>res?.data),
  create: (path, params) => httpAuth().post(`/${path}`, params.data),
  getOne: (path, params) => httpAuth().get(`/${path}/${params.id}`),
  update: (path, params) => httpAuth().put(`/${path}/${params.id}`, params.data),
  delete: (path, params) => httpAuth().delete(`/${path}/${params.id}`),
  deleteMany: (path, params) => httpAuth().delete(`/${path}/${params.ids.join(',')}/deleteMany`),
  createMany: (path, params) => httpAuth().post(`/${path}`, params.data),
  async updateUserProfile({ data }) {
    const me = localStorage.getItem('me');
    const jsonMe = JSON.parse(me)
    localStorage.setItem(
      'me',
      JSON.stringify({
        ...jsonMe,
        fullName: data.fullName || jsonMe.fullName,
        avatar: data.avatar,
      })
    );
    return Promise.resolve({ data });
  }
}