import { httpAuth } from "../config/service";

export const userInviteServices = {

  delete: (id) => httpAuth().delete(`/userInvite/${id}`),

  resendEmail: (id) => httpAuth().post(`/userInvite/resendEmail/${id}`),

  accept: (token, password) => httpAuth().post('/userInvite/accept', {token, password})

}
