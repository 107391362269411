import { Grid } from "@material-ui/core";

const SanitizedGrid = (props) => {
  const {basePath, ...propsWithoutBasePath} = props;
  return (
    <div style={{flexGrow: 1}}>
      <Grid {...propsWithoutBasePath} style={{width: '100%'}}/>
    </div>
  );
}

export default SanitizedGrid;