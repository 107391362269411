import React, { useState } from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Login } from 'ra-ui-materialui';
import LoginForm from './LoginForm';
import ForgotPassword from './modals/ForgotPassword';

const useStyles = makeStyles(
  (theme) => ({
    forgotPass: {
        padding: '0 1em 1em 1em',
        textAlign: 'end'
    },
  })
);

const LoginPage = (props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Login>
        <LoginForm />
          <div className={classes.forgotPass}>
            <Typography>
              <Link
                component="button"
                variant="body3"
                onClick={() => setOpen(true)}
              >
                Forgot Password?
              </Link>
            </Typography>
          </div>
      </Login>
      {open &&
        <ForgotPassword
          open={open}
          setOpen={setOpen}
        />
      }
    </>
  );
}

export default LoginPage;