import { SelectInput, SimpleForm, TextInput, useNotify } from "react-admin";
import { object, bool, func, string } from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { types } from "../../../constants/types";
import { FormToolbar } from "../../../utils/FormToolbar";
import { validateEmail } from "../../../utils/helpers";
import { userServices } from "../../../services/user";

const UserModal = ({ record, open, setOpen, refresh, resource, redirect }) => {
  const notify = useNotify();

  const save = data => {
    userServices.update(record.id,
      {
        fullName: data.fullName,
        title: data.title,
        email: data.email,
        type: data.type
      }).then(async () => { 
        await refresh();
        setOpen(false);
        notify(
          'User updated.',
          "success",
          {
            _:
              'User updated.'
          }
        );
      }).catch(() => {
        notify(
          "A technical error occured while updating the user. Please try later.",
          "warning",
          {
            _:
              "A technical error occured while updating the user. Please try later."
          }
        );
      });
  }

  const validate = (values) => {
    const errors = {};

    if(values.email) {
      errors.email = validateEmail(values.email);
    }
    if(values.type !== 'placeholder' && !values.email) {
      errors.email = 'Required';
    }

    return errors;
  }

  return (
    <Dialog fullWidth onClose={_ => setOpen(false)} open={open}>
      <DialogTitle>
        Edit user
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          save={save}
          record={record}
          toolbar={<FormToolbar onClose={_ => setOpen(false)} />}
          validate={validate}
        >
          <TextInput source="fullName" label={'User full name'} fullWidth />
          <TextInput source="title" label={'User title'} fullWidth />
          <TextInput source="email" label={'User e-mail'} fullWidth />
          <SelectInput source="type" label="Role" choices={types} fullWidth />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )


}

UserModal.propTypes = {
  record: object,
  open: bool,
  setOpen: func,
  refresh: func,
  resource: string,
  redirect: string
}

export default UserModal;