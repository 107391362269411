import { httpAuth } from "../config/service";

export const planServices = {
  getAll: () => httpAuth().get('/plan', {
    params: {
      sort: { field: 'group', order: 'ASC' }
    }
  }).then(res=>res?.data?.data),

  getValidPlans: (effectiveDate, groupLevel) =>  
    httpAuth().get(`/plan/${effectiveDate}/${groupLevel}/valids`).then(res=>res?.data)
}