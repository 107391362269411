import React from "react";
import {
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  ArrayInput,
  TextInput,
  required
} from "react-admin"
import { types } from "../../constants/types";
import { FormToolbar } from "../../utils/FormToolbar";
import { validateEmail } from "../../utils/helpers";

const UserView = (props) => {
  const validate = (values) => {
    const errors = {
      users: []
    };

    for(let i = 0; i < values.users?.length; i+=1) {
      if(values.users[i]?.email) {
        errors.users[i] = { email: validateEmail(values.users[i].email) };
      }
      if(values.users[i]?.type !== 'placeholder' && !values.users[i]?.email) {
        errors.users[i] = { email: 'Required' };
      }
    }
    
    return errors;
  }

  return (
    <>
      <SimpleForm {...props} validate={validate} toolbar={<FormToolbar />}>
        <ArrayInput source="users" label={'Users'} >
          <SimpleFormIterator>
            <TextInput
              source="fullName"
              label={'User full name'}
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="title"
              label={'User title'}
              fullWidth
            />
            <TextInput
              source="email"
              label={'User e-mail'}
              fullWidth
            />
            <SelectInput
              source="type"
              label="Role"
              choices={types}
              validate={[required()]}
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </>
  )
}

export default UserView;