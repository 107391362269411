import React from 'react';
import { Admin, Resource } from "react-admin";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { authProvider } from "./providers/authProvider";
import { dataProvider } from "./providers/dataProvider";
import { People, Assignment, AttachMoney, MarkunreadMailbox, BusinessCenter, Equalizer } from '@material-ui/icons';
import Proposal from "./components/list/Proposal";
import ProposalCreate from "./components/create/Proposal"
import UserCreate from "./components/create/User";
import SetPassword from './components/form/SetPassword';
import ResetPassword from './components/form/ResetPassword';
import UserList from './components/list/User';
import EditProposal from "./components/edit/Proposal";
import Report from "./components/list/Report";
import { ConfirmProvider } from "material-ui-confirm";
import Prices from './components/list/Prices';
import Region from './components/list/Region';
import Plan from './components/list/Plan';
import { ProfileEdit } from './components/layout/MyProfile';
import MyLayout from './components/layout/MyLayout';
import EditPlan from './components/edit/Plan';
import PlanCreate from './components/create/Plan';
import Login from './components/form/Login';
import Analytic from './components/list/Analytic';
import { createBrowserHistory as createHistory } from 'history';

function App() {
  const history = createHistory();

  const nonProtectedRoutes = [
    {
      path: "/setPassword/:token?",
      component: SetPassword
    },
    {
      path: "/resetPassword/:token?",
      component: ResetPassword
    }
  ]

  if(nonProtectedRoutes
    .find(route=>
      window.location.pathname.includes(
        route.path.replace(/:([a-z])\w+\??/, "")
      )
    )
  ) {
    return (
      <Router>
        <Switch>
          {nonProtectedRoutes.map(route=>
            <Route
              exact
              {...route}
            />
          )}
        </Switch>
      </Router>
    )
  }

  return (
    <ConfirmProvider>
      <Admin
        history={history}
        authProvider={authProvider}
        dataProvider={dataProvider}
        customRoutes={[
          <Route
            key="my-profile"
            path="/myProfile"
            render={() => <ProfileEdit />}
          />
        ]}
        loginPage={() => <Login />}
        layout={MyLayout}
        >
        {permissions => {
          return [
            <Resource name={'proposal'} list={Proposal} icon={Assignment} edit={EditProposal} create={ProposalCreate}/>,
            permissions.includes('admin') || permissions.includes('agent')
              ? <Resource name={'report'} list={Report} />
              : null,
            permissions.includes('admin')
              ? [
                  <Resource name={'prices'} list={Prices} icon={AttachMoney} />,
                  <Resource name={'region'} list={Region} icon={MarkunreadMailbox} />,
                  <Resource name={'plan'} list={Plan} edit={EditPlan} create={PlanCreate} icon={BusinessCenter} />,
                  <Resource name={'analytic'} list={Analytic} icon={Equalizer} />,
                  <Resource options={{ label: 'Team Management' }} name={'user'} list={UserList} icon={People} create={UserCreate}/>,
                ]
              : null,
          ]
        }}
      </Admin>
    </ConfirmProvider>
  );
}

export default App;
