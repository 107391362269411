import React from 'react';
import { Datagrid, ExportButton, List, NumberField, TextField, TopToolbar } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";

const transformRows = csvRows => csvRows.map((row) => ({
  zip: row.ZIP,
  region: Number(row.REGION)
}));

const ListActions = (props) => {
  const { className, total } = props;
  
  const config = {
    transformRows
  }

  return (
    <TopToolbar className={className}>
      <ImportButton {...props} {...config} />
      <ExportButton disabled={total === 0} />
    </TopToolbar>
  );
};

const Region = props => {
  return <List {...props} empty={false} actions={<ListActions />} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick={'edit'} >
      <TextField source={'id'} />
      <TextField source={'zip'} />
      <NumberField source={'regionGroup.region'} label="Region Group" sortable={false} />
    </Datagrid>
  </List>
}

export default Region;