import React from 'react';
import { Toolbar, SaveButton, useRedirect } from "react-admin";
import { Button } from '@material-ui/core';

export const FormToolbar = props => {
  const redirect = useRedirect();

  const onCancel = () => {
    const { onClose } = props;
    
    const url = window.location.href.includes('/proposal/create/report')
      ? '/report'
      : `/${props.resource}`;

    return onClose
      ? onClose()
      : redirect(url);
  }

  return (
    <Toolbar {...props} >
      <SaveButton
        label={props.okLabel || 'SAVE'}
        submitOnEnter={false}
      />
      <Button onClick={onCancel}>
        CANCEL
      </Button>
    </Toolbar>
  );
}