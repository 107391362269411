import React, { useState } from "react";
import { bool, func } from "prop-types";
import { SimpleForm, TextInput, useNotify, required } from "react-admin";
import { Loading } from "ra-ui-materialui";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { validateEmail } from "../../../utils/helpers";
import { userServices } from "../../../services/user";
import { FormToolbar } from "../../../utils/FormToolbar";

const ForgotPassword = ({ open, setOpen }) => {
  const notify = useNotify();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);

  const save = data => {
    const { email } = data;

    confirm({description: 'Are you want to send a reset password email?'})
    .then(() => {
      setLoading(true);

      userServices.resetPassword(email)
        .then(() => {
          setLoading(false);
          setOpen(false)
          notify(
            "A reset password email has been sent.",
            "success",
            {
              _:
                "A reset password email has been sent."
            }
          );
        }).catch(() => {
          setLoading(false);
          setOpen(false)
          notify(
            "A technical error occured while reseting the password. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while reseting the password. Please try later."
            }
          );
        });
    });
  }

  const validate = (values) => {
    const errors = {};

    if(values.email) {
      errors.email = validateEmail(values.email);
    }

    return errors;
  }

  return (
    <Dialog fullWidth onClose={_ => setOpen(false)} open={open}>
      <DialogTitle>
        ForgotPassword
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          save={save}
          toolbar={<FormToolbar okLabel="send" onClose={_ => setOpen(false)} />}
          validate={validate}
        >
          <TextInput source="email" label={'Your e-mail'} fullWidth validate={[required()]} />
        </SimpleForm>
        {loading && <Loading loadingPrimary="Sending a reset password e-mail" />}
      </DialogContent>
    </Dialog>
  )


}

ForgotPassword.propTypes = {
  open: bool,
  setOpen: func,
}

export default ForgotPassword;