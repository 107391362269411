import React, {useEffect, useState} from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  downloadCSV,
  Pagination,
  Loading,
  useRefresh,
  useNotify
} from 'react-admin';
import { useHistory } from "react-router";
import jsonExport from 'jsonexport';
import moment from 'moment';
import {userServices} from "../../services/user";
import { Card, MenuItem, Select } from "@material-ui/core";
import { proposalServices } from "../../services/proposal";
import { useConfirm } from "material-ui-confirm";
import { loginService } from "../../services/login";
import { reportServices } from "../../services/report";
import Filters from "../../utils/Filters";
import {handleExcel} from "../form/utils/employeeHelper";
import { CreatedByField, LastModifiedField, TotalCensusCount } from "../../utils/helpers";
import { ancillaryPlansToDownloadSeparated } from "../../constants/plans";

const Report = props => {
  const me = loginService.cachedMe();
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState();
  const [redirect, setRedirect] = useState();
  const confirm = useConfirm();
  const refresh = useRefresh();
  const notify = useNotify();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setUsers(await userServices.getAll())
    })()
  }, [])

  const handleUpdate = record => setRedirect(`/proposal/${record.id}/report`)

  const handleDuplicate = record => {
    const omitId = ({id, createdAt, updatedAt, user, createdBy, ...rest}) => rest
    const url = `/proposal/create/report?source=${JSON.stringify(omitId(record))}`
    setRedirect(url)
  }

  const separatedMedicalAndAncillaryPlans = (record) => {
    const plans = record.plans
    const effectiveYear = moment(record.effectiveDate).year()
    const separatedPlans = ancillaryPlansToDownloadSeparated[effectiveYear]
    const hasAncillaryPlans = plans.some(plan => separatedPlans?.includes(plan.name))
    return hasAncillaryPlans
  }

  const handlePdf = async record => {
    setLoading(["Generating PDF", "It might take a while"])
    await proposalServices.downloadPDF(
      record.id, 
      record.company?.name, 
      record.updatedAt,
      separatedMedicalAndAncillaryPlans(record)

    )
    setLoading(null)
  }

  const handleDelete = record => {
    confirm({description: `Are you sure you want to delete this report?`})
    .then(() => {
      reportServices.delete(record.id).then(() => {
        refresh();
        notify(
          'Report deleted.',
          "success",
          {
            _:
              'Report deleted.'
          }
        );
      }).catch(() => {
        setLoading(false);
        notify(
          "A technical error occured while deleting the report. Please try later.",
          "warning",
          {
            _:
              "A technical error occured while deleting the report. Please try later."
          }
        );
      });
    });
  }

  const handleAction = (action, record) => {
    const handlers = {
      'update': handleUpdate,
      'duplicate': handleDuplicate,
      'pdf': handlePdf,
      'excel': handleExcel,
      'delete': handleDelete
    }

    return handlers[action](record)
  }

  const NewPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300, 500]} {...props} />
  );

  const depCount = employeeCensus =>
    employeeCensus.reduce((acc, curValue) => acc + (curValue.children?.length || 0), 0)

  const exportData = proposals => {
    const proposalsToExport = proposals.map(proposal => {
      const {
        updatedAt,
        company,
        effectiveDate,
        brokerName,
        user,
        employeeCensus,
      } = proposal
      return {
        date: updatedAt,
        contactName: company.contactName,
        name: company.name,
        city: company.city,
        zip: company.zip,
        effectiveDate,
        brokerName,
        salesRepresentative: user?.fullName,
        eeCount: employeeCensus.length,
        depCount: depCount(employeeCensus)
      }
    })
    jsonExport(proposalsToExport, {}, (err, csv) => {
      if(!err)
        downloadCSV(csv, 'report')
    })
  }

  return <>
    {redirect && history.push(redirect)}
    { loading
      ? <Loading loadingPrimary={loading[0]} loadingSecondary={loading[1]}/>
      : <List
          {...props}
          filters={<Filters users={users} />}
          bulkActionButtons={false}
          exporter={exportData}
          pagination={<NewPagination/>}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid>
            <DateField source={'updatedAt'} label={'Date'}/>
            <TextField source={'company.contactName'} label="Contact Name"/>
            <TextField source={'company.name'} label="Company"/>
            <FunctionField label={'City - ZIP'} render={record => `${record?.company.city} - ${record?.company.zip}`}/>
            <FunctionField label={'Effective date'} render={record => record.effectiveDate && moment(record.effectiveDate).format("MM/DD/YYYY")}/>
            <TextField source={'brokerName'} label="Broker"/>
            <TextField source={'user.fullName'} label="Sales represantative"/>
            <LastModifiedField source="updatedAt" label="Last Modified"/>
            <CreatedByField source="updatedAt" label="Created By"/>
            <TotalCensusCount label="Total Census Count"/>
            <FunctionField
              label="Status"
              render={record =>
                <Card style={{textAlign: 'center'}}>
                  {record.status}
                </Card>
              }
            />
            <FunctionField render={record =>
              <Select value={0} onChange={e => handleAction(e.target.value, record)}>
                <MenuItem disabled value={0}>Actions</MenuItem>
                <MenuItem value={'update'}>Update</MenuItem>
                <MenuItem value={'duplicate'}>Duplicate</MenuItem>
                <MenuItem value={'pdf'}>PDF</MenuItem>
                <MenuItem value={'excel'}>Excel</MenuItem>
                { me.type === 'admin' && <MenuItem value={'delete'}>Delete</MenuItem> }
              </Select>
                }/>
          </Datagrid>
        </List>
    }
  </>
  
}

export default Report