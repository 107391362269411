// @ts-check

import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

/**
 * @typedef {'list' | 'create'} ListOrCreate
 * 
 * @typedef {{
 *   value: ListOrCreate;
 *   onChange: (value: ListOrCreate) => void;
 * }} ListOrCreateCompanyRadioGroup;
 */

/** @type {React.FC<ListOrCreateCompanyRadioGroup>} */
const ListOrCreateCompanyRadioGroup = (props) => {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Company</FormLabel>
            <RadioGroup row aria-label="company_list_or_create" name="Company Mode"  value={props.value} onChange={e => props.onChange(/** @type {ListOrCreate} */ (e.target.value))}>
                <FormControlLabel value="list" control={<Radio />} label="List" />
                <FormControlLabel value="create" control={<Radio />} label="Create" />
            </RadioGroup>
        </FormControl>
    );
};

export default ListOrCreateCompanyRadioGroup;
