import {object,func} from "prop-types";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  makeStyles, TableCell
} from "@material-ui/core";
import {Edit as EditIcon, Add as AddIcon} from '@material-ui/icons';
import moment from 'moment'
import ProposalBasicModal from "./modals/ProposalBasic";
import React, {useState, useEffect} from "react";
import EmployeeModal from "./modals/Employee";
import {useConfirm} from "material-ui-confirm";
import {cleanEmpty} from "../../utils/helpers";
import {Redirect} from 'react-router-dom'
import {proposalStatus} from "../../config/constants";
import { proposalServices } from "../../services/proposal";
import { priceService } from "../../services/price";
import {mountEmployeesPrice, removePlans, renderDate} from "./utils/employeeHelper";
import {useNotify} from "react-admin";
import { orderPlans } from "./utils/plansHelper";

const ProposalPreview = ({record: initialRecord, save, ...props}) => {
  const [basicOpen, setBasicOpen] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [employeeOpen, setEmployeeOpen] = useState(false)
  const [record, setRecord] = useState(initialRecord)
  const [employeeIndex, setEmployeeIndex] = useState(null)
  const [cancel, setCancel] = useState()
  const [employeesPrice, setEmployeesPrice] = useState([]);
  const [needSimulate, setNeedSimulate] = useState(false);
  const confirm = useConfirm();
  const notify = useNotify();

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    employeeActions: {
      display: 'flex',
      alignItems: 'flex-end',
      float: 'right',
      padding: '0px 10px',
      justifyContent: 'end'
    }
  });

  useEffect(() => {
    async function fetchData() {
      const data = await proposalServices.getEmployeesPrice(record.id);
      const employeesPriceArray = mountEmployeesPrice(data)
      
      setEmployeesPrice(employeesPriceArray);
    }
    fetchData();
  }, [record.id]);

  useEffect(() => {
    async function fetchData() {
      if(needSimulate) {
        const data = await priceService.simulate({
          effectiveDate: record.effectiveDate,
          employeeCensus: record.employeeCensus,
          plans: record.plans,
          company: record.company,
          groupLevel: record.groupLevel
        })
        const employeesPriceArray = mountEmployeesPrice(data)
        
        setEmployeesPrice(employeesPriceArray);
        setNeedSimulate(false)
      }
    }
    fetchData();
  }, [needSimulate, record.effectiveDate, record.employeeCensus, record.plans, record.company, record.groupLevel]);


  const handleEdit = (record, index) => {
    setSelectedEmployee(record)
    setEmployeeOpen(true)
    setEmployeeIndex(index)
  }

  const handleDelete = (_, index) => {
    confirm({description: `Are you sure you want to delete employee #${index}?`})
      .then(()=>{
        const recordCopy = Object.assign({}, record)
        recordCopy.employeeCensus.splice(index,1)
        setRecord(recordCopy)
      })
  }

  const addNewEmployee = () => {
    setSelectedEmployee(null)
    setEmployeeOpen(true)
    setEmployeeIndex(null)
  }

  const handleAction = (action, record, index) => {
    const handlers = {
      'edit': handleEdit,
      'delete': handleDelete,
    }

    return handlers[action](record, index)
  }

  const saveEmployee = (employee, index) => {
    const recordCopy = Object.assign({}, record)
    if (index !== null) {
      recordCopy.employeeCensus[index] = employee
      setRecord(recordCopy)
    } else {
      recordCopy.employeeCensus.push(employee)
    }
  }

  const {company = {}} = record
  const classes = useStyles();

  const submit = () => {
    if(record?.groupLevel === 'm1' && record?.employeeCensus?.length > 1) {
      notify('You need to choose group M2 if you want to add more than 1 employee')
      return;
    }
    const recordCopy = Object.assign({}, record)
    delete recordCopy.user
    delete recordCopy.createdAt;
    delete recordCopy.updatedAt;
    delete recordCopy.createdBy;
    delete recordCopy.updatedBy;
    recordCopy.plans = recordCopy.plans.map(plan=>plan.id || plan)
    save(cleanEmpty(recordCopy), '/proposal')
  }

  return <div key={record}>
    {cancel && 
      <Redirect
        to={window.location.href.includes('/report') 
          ? '/report'
          : '/proposal'
        }
      />
    }
    <ProposalBasicModal
      record={record}
      open={basicOpen}
      setOpen={setBasicOpen}
      setRecord={setRecord}
      setNeedSimulate={setNeedSimulate}
    />
    <EmployeeModal
      record={selectedEmployee}
      open={employeeOpen}
      setOpen={setEmployeeOpen}
      saveEmployee={saveEmployee}
      index={employeeIndex}
      setNeedSimulate={setNeedSimulate}
    />
    <div className={'selectStatus'}>
      <b>Status: </b>
      <Select
        defaultValue={record.status}
        onChange={e=>setRecord({...record, status: e.target.value})}
        style={{padding: '0px 15px'}}
      >
        <MenuItem value={proposalStatus.DRAFT}>Draft</MenuItem>
        <MenuItem value={proposalStatus.COMPLETE}>Complete</MenuItem>
        <MenuItem value={proposalStatus.SOLD}>Sold</MenuItem>
        <MenuItem value={proposalStatus.DECLINED}>Declined</MenuItem>
        <MenuItem value={proposalStatus.INVALID}>Invalid</MenuItem>
      </Select>
    </div>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Contact name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>ZIP</TableCell>
            <TableCell>Effect date</TableCell>
            <TableCell>Broker</TableCell>
            <TableCell>Plans</TableCell>
            <TableCell>Sales representative</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{company.name}</TableCell>
            <TableCell>{company.contactName}</TableCell>
            <TableCell>{company.city}</TableCell>
            <TableCell>{company.zip}</TableCell>
            <TableCell>{moment(record.effectiveDate).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{record.brokerName}</TableCell>
            <TableCell>
              {orderPlans(record.plans, moment(record?.effectiveDate).year())?.map(plan =>
                <div>{plan?.name}</div>
              )}
            </TableCell>
            <TableCell>{record.user?.fullName}</TableCell>
            <TableCell>
              <Button onClick={_ => setBasicOpen(true)}><EditIcon/> Edit</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <Typography
      component={'h2'}
      style={{padding: '30px 15px 15px 15px', fontWeight: 'bold'}}
    >
      Employee census
    </Typography>

    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>ZIP</TableCell>
            <TableCell>DOB / Age</TableCell>
            <TableCell>Spouse DOB / Age</TableCell>
            <TableCell>Children DOB / Age</TableCell>
            <TableCell>Plans</TableCell>
            <TableCell>Total Monthly Rates</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody key={record.groupLevel}>
          {record.employeeCensus?.map((employee, index) =>
            <TableRow key={index}>
              <TableCell>{employee.name}</TableCell>
              <TableCell>{employee.zip}</TableCell>
              <TableCell>{renderDate(employee.birthdate, employee.age, record.effectiveDate)}</TableCell>
              <TableCell>{renderDate(employee.spouseBirthdate, employee.spouseAge, record.effectiveDate)}</TableCell>
              <TableCell>
                {
                  employee.children?.map(child =>
                    <div>{renderDate(child.birthdate, child.age, record.effectiveDate)}</div>
                  )
                }
              </TableCell>
              <TableCell>
                {orderPlans(record.plans, moment(record?.effectiveDate).year())?.map(plan =>
                  !removePlans.includes(plan.name) &&
                    <div>{plan.name}</div>
                )}
              </TableCell>
              <TableCell>
                {orderPlans(record.plans, moment(record?.effectiveDate).year())?.map(plan =>{
                  return !removePlans.includes(plan.name) &&
                    <div>{employeesPrice[plan.name]?employeesPrice[plan.name][index]:0}</div>
                })}
              </TableCell>
              <TableCell>
                <Select value={0} onChange={e => handleAction(e.target.value, employee, index)}>
                  <MenuItem disabled value={0}>Actions</MenuItem>
                  <MenuItem value={'edit'}>Edit</MenuItem>
                  <MenuItem value={'delete'}>Delete</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {(record.groupLevel !== 'm1' || (record.groupLevel === 'm1' && record.employeeCensus.length === 0)) && 
      <div className={'newEmployee'}>
        <Button onClick={addNewEmployee}><AddIcon/> Add new employee</Button>
      </div>
    }
    <div className={'buttonsBottom'}>
      <Button variant="contained" color="primary"onClick={submit}>Submit</Button>
      <Button variant="contained" onClick={_=>setCancel(true)}>Cancel</Button>
    </div>
  </div>
}

ProposalPreview.propTypes = {
  record: object,
  save: func
}

export default ProposalPreview