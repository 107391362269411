import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';

const Plan = props => {
  return <List {...props} empty={false} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick={'edit'} >
      <TextField source={'id'} />
      <TextField source={'name'} />
      <TextField source={'group'} />
      <TextField source={'order'} />
    </Datagrid>
  </List>
}

export default Plan;