import { httpAuth } from "../config/service";
import { loginService } from "../services/login";

export const authProvider = {
  login: async ({ username, password }) => {
    try {
      const token = await loginService.performLogin(username, password)
      const me = await loginService.me(token)
      localStorage.setItem('token', token);
      localStorage.setItem('me', JSON.stringify(me));
      httpAuth().interceptors.request.use(req=>{
        req.headers = {
          Authorization: `Bearer ${token}`
        }
        return req
      })
      return Promise.resolve();
    }catch(e) {
      return Promise.reject(e.statusCode)
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },

  checkError: ({ response: { status } }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');

      return Promise.reject()
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },

  getIdentity: () => {
    const me = JSON.parse(localStorage.getItem('me'))
    if(me){
      return Promise.resolve(me)
    }else{
      return Promise.reject()
    }
  },

  getPermissions: async () => {
    const me = await authProvider.getIdentity()
    if(me){
      return Promise.resolve(me.scope)
    }else{
      return Promise.reject()
    }
  },
}
