import React from "react";
import classnames from 'classnames';
import { useSafeSetState, useTranslate } from "react-admin";
import { Field, Form } from 'react-final-form';
import { Avatar, Button, Card, CardActions, CircularProgress, makeStyles, TextField } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import { userServices } from "../../services/user";

const useStyles = makeStyles(
  (theme) => ({
      main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage:
            'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
      },
      card: {
        minWidth: 300,
        marginTop: '6em',
      },
      avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
      },
      form: {
        padding: '0 1em 1em 1em',
      },
      input: {
        marginTop: '1em',
      },
      button: {
        width: '100%',
      },
      icon: {
        backgroundColor: theme.palette.secondary[500],
      },
  }),
);

const ResetPassword = (props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const classes = useStyles(props);

  const Input = ({
    meta: { touched, error },
    input: inputProps,
    ...props
  }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
  );

  const validate = (values) => {
      const errors = { password: undefined, confirmPassword: undefined };

      if (!values.password) {
        errors.password = translate('Password is required');
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = translate('Confirm password is required');
      }
      if(values.confirmPassword && values.password) {
        if(values.password !== values.confirmPassword) {
          errors.confirmPassword = translate('Passwords need match');
        }
      }
     
      return errors;
  };

  const submit = (values) => {
    setLoading(true);

    const token = props.match.params.token;

    userServices.changePassword(
      token,
      {
        password: values.password,
        confirmPassword: values.confirmPassword
      }
    ).then(() => {
      setLoading(false);
      window.location.href = '/';
    }).catch((error) => {
      setLoading(false);
      // TODO: SEND NOTIFICATION
    });
  };

  return (
    <>
      <div
        className={classnames(classes.main)}
      >
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      id="password"
                      name="password"
                      component={Input}
                      label="Password"
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      component={Input}
                      label="Confirm Password"
                      type="password"
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                  >
                    {loading && (
                      <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    SET PASSWORD
                  </Button>
                </CardActions>
              </form>
            )}
          />
        </Card>
      </div>
    </>
  )
}

export default ResetPassword;