import { http, httpAuth } from "../config/service";

export const userServices = {
  getAll: () => httpAuth().get('/user', {
    params: {
      pagination: {page:1, perPage: 999}
    }
  }).then(res=>res?.data?.data),

  delete: (id) => httpAuth().delete(`/user/${id}`),

  update: (id, data) => httpAuth().put(`/user/${id}`, data),

  resetPassword: (id) => httpAuth().post(`/user/resetPassword/${id}`, {}),

  changePassword: (token, data) => http.put(
    '/user/resetPassword',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  ),

  setPassword: (token, data) => http.post(
    '/user/setPassword',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  ),

  updateProfile: (data) => httpAuth().put('/user/profile', data),

  updateProfilePhoto: (data) => httpAuth().post('/user/profile/photo', data),


}