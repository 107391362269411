import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Datagrid, TextField, useDataProvider, useNotify, DateField, EmailField, FunctionField, ReferenceField } from 'react-admin';
import { MenuItem, Select } from '@material-ui/core';
import { useConfirm } from "material-ui-confirm";
import { userInviteServices } from '../../services/userInvite';
import Card from '@material-ui/core/Card';

const sort = { field: 'createdAt', order: 'DESC' };

/** @type {React.FC<{show: boolean}>} */
const UserInviteList = (props) => {
    const dataProvider = useDataProvider();
    const confirm = useConfirm();
    const notify = useNotify();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const ids = useMemo(() => Object.keys(data), [data]);
    const isRecordExpired = (record) => ((new Date(record.expiresAt)).getTime() - (new Date()).getTime()) <= 0
    const statuses = useMemo(() => (
        Object.keys(data).map(userInvited => {
            const record = data[userInvited];
            if (record.acceptanceDate) {
                return { [record.id]: 'accepted' };
            }
            else if (isRecordExpired(record)) {
                return { [record.id]: 'overdue' };
            }
            else {
                return { [record.id]: 'awaiting' };
            }
        }).reduce((previousUserInvited, currentUserInvited) => 
            Object.assign(previousUserInvited, currentUserInvited), 
            {}
        )
    ), [data]);

    const fetchData = useCallback(async () => {
        const { data } = await dataProvider.getList('userInvite', {
            pagination: { page: 1, perPage: 99 },
            sort,
            filter: {}
        });
        const users = data.map((userInvited, index) => 
            ({ [index + 1]: userInvited })
        ).reduce((previousUser, currentUser) => Object.assign(previousUser, currentUser), {});
        setData(users);
        setLoading(false);
    }, [dataProvider])

    useEffect(() => {
        fetchData()
    }, [props.show, fetchData]);

    const handleAction = (action, record) => {
        const handlers = {
            'resendEmail': handleResendEmail,
            'delete': handleDelete
        }

        return handlers[action](record);
    }

    const handleResendEmail = (record) => {
        confirm({ description: 'Are you sure you want to resend the invite?' })
            .then(() => {
                setLoading(true);
                userInviteServices.resendEmail(record.id).then(() => {
                    notify("An invite email has been sent.", "success", { _: "A reset password email has been sent." });
                    fetchData();
                }).catch(() => {
                    setLoading(false);
                    notify("A technical error occured while resending the email. Please try later.", "warning", { _: "A technical error occured while reseting the password. Please try later." });
                });
            });
    }

    const handleDelete = record => {
        confirm({ description: `Are you sure you want to delete the invite of: ${record.fullName}?` })
            .then(() => {
                userInviteServices.delete(record.id).then(() => {
                    notify('Invite deleted.', "success", { _: 'Invite deleted.' });
                    fetchData();
                }).catch(() => {
                    setLoading(false);
                    notify("A technical error occured while deleting the invite. Please try later.", "warning", { _: "A technical error occured while deleting the invite. Please try later." });
                });
            });
    }

    if (!props.show) {
        return null;
    }

    return (
        <>
            <Card style={{ marginTop: 60 }}>
                <Datagrid
                    data={data}
                    ids={ids}
                    currentSort={sort}
                >
                    <TextField source="fullName" />
                    <EmailField source="email" emptyText="-" />
                    <TextField source="title" emptyText="-" />
                    <TextField source="type" />
                    <ReferenceField label="Inviter" source="inviterId" reference="user">
                        <TextField source="fullName" />
                    </ReferenceField>
                    <DateField source='createdAt' showTime sortable/>
                    <DateField source='expiresAt' showTime/>
                    <FunctionField label="Status" render={record => {
                        const status = statuses[record.id];
                        switch (status) {
                            case 'accepted':
                                return <span style={{ color: '#05bc05' }}>Accepted</span>;
                            case 'overdue':
                                return <span style={{ color: 'red' }}>Overdue</span>;
                            default:
                                return <span style={{ color: 'orange' }}>Awaiting acceptance</span>;
                        }
                    }} />
                    <FunctionField render={record =>
                        <Select disabled={loading} value={0} onChange={e => handleAction(e.target.value, record)}>
                            <MenuItem disabled value={0}>Actions</MenuItem>
                            <MenuItem disabled={statuses[record.id] === 'accepted'} value="resendEmail">Resend Email</MenuItem>
                            <MenuItem disabled={statuses[record.id] === 'accepted'} value="delete">Delete</MenuItem>
                        </Select>
                    } />
                </Datagrid>
            </Card>
        </>
    );
};

export default UserInviteList;
