import React from "react";
import { FormDataConsumer } from 'react-admin'
import {
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Button as MaterialButton
} from "@material-ui/core"
import { boolean, func } from "prop-types";

const GroupLevel = ({ 
  openAlertLevelGroupChange, 
  setOpenAlertLevelGroupChange, 
  onOk, 
  onCancel 
}) => {
  return (
  	<FormDataConsumer>
      {({ formData }) => (
        <Dialog
          open={openAlertLevelGroupChange}
          onClose={() => setOpenAlertLevelGroupChange(false)}
        >
          <DialogTitle>Change Group Level</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Changing to M1 will delete all employes below { 
                (formData?.employeeCensus && formData.employeeCensus[0]?.name)
                || 'employee #1'
            }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={() => onCancel()}>
              Cancel
            </MaterialButton>
            <MaterialButton onClick={() => onOk(formData)}>
              OK
            </MaterialButton>
          </DialogActions>
        </Dialog>
      )}
    </FormDataConsumer>
  )
}

GroupLevel.propTypes = {
  openAlertLevelGroupChange: boolean,
  setOpenAlertLevelGroupChange: func, 
  onOk: func, 
  onCancel: func
}

export default GroupLevel;
