export const types = [
  {
    id: 'admin',
    name: 'Admin'
  },
  {
    id: 'agent',
    name: 'Agent'
  },
  {
    id: 'sales',
    name: 'Sales'
  },
  {
    id: 'placeholder',
    name: 'Placeholder'
  }
];