import { Create, useNotify, useRefresh, useRedirect } from 'react-admin';
import PlanView from "../form/Plan";

const PlanCreate = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Element created');
    redirect('/plan');
    refresh();
  }

  return <Create onSuccess={onSuccess} {...props}>
    <PlanView />
  </Create>
}

export default PlanCreate;