import { httpAuth } from "../config/service";

export const companyServices = {
  /** @type {() => Promise<import("../types/models").Company[]>} */
  getAll: () => httpAuth().get('/company', {
    params: {
      pagination: {page:1, perPage: 999}
    }
  }).then(res=> (res?.data?.data))
};
