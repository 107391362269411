import axios from "axios";

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export const httpAuth = () => axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
})
