import React from "react";
import {
  SimpleForm,
  TextInput,
  required
} from "react-admin";
import { FormToolbar } from "../../utils/FormToolbar";

const PlanView = (props) => {
  return (
    <>
      <SimpleForm {...props} toolbar={<FormToolbar />}>
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="group" fullWidth />
        <TextInput source="order" fullWidth />
      </SimpleForm>
    </>
  )
}

export default PlanView;