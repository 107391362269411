import React from "react";
import { Card } from "@material-ui/core";
import Filters from "../filters/Analytic";
import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DateField,
} from "react-admin";
import { 
  CreatedByField, 
  LastModifiedField, 
  FullNameWithEmailField,
  RecordDateStringField
} from '../../utils/helpers';

const Analytic = (props) => {
  return (
    <List {...props} 
      empty={false} 
      bulkActionButtons={false} 
      filters={<Filters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <FunctionField
          label="Status"
          source={"status"}
          render={(record) => (
            <Card style={{ textAlign: "center" }}>{record.status}</Card>
          )}
        />
        <RecordDateStringField field="effectiveDate" source={'effectiveDate'}/>
        <FullNameWithEmailField source={"user.fullName"} label={"Full Name"} />
        <TextField source={"user.title"} label={"Title"} />
        <TextField source={"user.type"} label={"Type"} />
        <LastModifiedField source="updatedAt" label="Last Modified"/>
        <CreatedByField source="createdAt" label="Created By"/>
        <DateField source={"user.loggedIn"} label={"Logged In"}/>
      </Datagrid>
    </List>
  );
};

export default Analytic;
