import moment from "moment";
import { httpAuth } from "../config/service";
import { downloadByURL } from "../utils/helpers";

export const proposalServices = {
  getUrlToPDF: (id, onlyAncillaryPlans = false) => httpAuth()
    .get(`/proposal/${id}/pdf?onlyAncillaryPlans=${onlyAncillaryPlans}`)
    .then(response => {
      const medicalPlansBuffer = Buffer.from(response.data.medicalPlans.data)
      const medicalPlansUrl = window.URL.createObjectURL(new Blob([medicalPlansBuffer], { type: 'application/pdf' }))
      if(onlyAncillaryPlans) {
        const ancillaryPlansBuffer = Buffer.from(response.data.ancillaryPlans.data)
        const ancillaryPlansUrl = window.URL.createObjectURL(new Blob([ancillaryPlansBuffer], { type: 'application/pdf' }))
        return [ medicalPlansUrl, ancillaryPlansUrl ]
      }
      return [ medicalPlansUrl ]
    }),
    
  downloadPDF: async (id, companyName, lastModified, onlyAncillaryPlans = false) => {
    const date = moment(lastModified).format('MM-DD-YYYY-HH-MM')

    if(onlyAncillaryPlans) {
      const [ medicalPlansUrl, ancillaryPlansUrl ] = await proposalServices.getUrlToPDF(id, true) 
    
      downloadByURL(medicalPlansUrl, `${companyName}-${date}-${onlyAncillaryPlans ? "Medical-Plans" : ""}`)
      downloadByURL(ancillaryPlansUrl, `${companyName}-${date}-Ancillary-Plans`)
      return
    }

    const [ medicalPlansUrl ] = await proposalServices.getUrlToPDF(id)
    downloadByURL(medicalPlansUrl, `${companyName}-${date}`)
    return
  },

  getEmployeesPrice: id => httpAuth()
  .get(`/proposal/${id}/prices`)
  .then(res=> res?.data)
}