import React from 'react';
import { Datagrid, ExportButton, List, NumberField, TextField, TopToolbar, FunctionField } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import { RecordDateStringField } from '../../utils/helpers';
import { Button } from "@material-ui/core";
import { priceService } from "../../services/price";
import { useConfirm } from "material-ui-confirm";

const transformRows = csvRows => csvRows.map((row) => ({
  effectiveDate: row.EFFECTIVE_DATE,
  highAge: Number(row.HIGH_AGE),
  lowAge: Number(row.LOW_AGE),
  premium: Number(row.PREMIUM),
  regionGroup: Number(row.REGION),
  planName: row.BENEFIT_PLAN,
  group: row.GROUP,
  groupLevel: row.GROUP_LEVEL?.toLowerCase()
}));

const ListActions = (props) => {
  const { className, total } = props;

  const confirm = useConfirm();

  const config = {
    transformRows
  }

  const deleteAll = () => {
    confirm({ description: 'Are you sure?' })
      .then(async () => {
        await priceService.deleteAll()
      })
  }

  return (
    <TopToolbar className={className}>
      <Button onClick={deleteAll}>Delete ALL</Button>
      <ImportButton {...props} {...config} />
      <ExportButton disabled={total === 0} />
    </TopToolbar>
  );
};

const Prices = props => {
  return <List {...props} empty={false} actions={<ListActions />} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick={'edit'} >
      <TextField source={'id'} />
      <RecordDateStringField field="effectiveDate" source={'effectiveDate'} />
      <NumberField source={'lowAge'} />
      <NumberField source={'highAge'} />
      <FunctionField
        label="Premium"
        textAlign="right"
        align="right"
        render={record => {
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          return formatter.format(record.premium);
        }}
      />;
      <TextField source={'plan.name'} label="Plan Name" sortBy={'planId'} />
      <TextField source={'regionGroup.region'} label={"Region"} sortBy={'regionId'} />
      <TextField source={'groupLevel'} label={"Group level"} />
    </Datagrid>
  </List>
}

export default Prices;