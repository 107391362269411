import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext
} from "react";
import {
  TextInput,
  ImageInput,
  ImageField,
  SimpleForm,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  useGetIdentity,
  useAuthProvider,
  required
} from "react-admin";
import { useHistory } from "react-router-dom";
import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import SanitizedGrid from "../form/utils/sanitizedGrid";
import { useConfirm } from "material-ui-confirm";
import { validateEmail } from "../../utils/helpers";
import { userServices } from "../../services/user";

const useStyles = makeStyles(
  (theme) => ({
    changeEmail: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      "& p": {
        margin: 0,
        padding: 0
      },
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
  }),
);

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1)
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const history = useHistory();
  const [saving, setSaving] = useState();
  const [loading, setLoading] = useState(false);
  const { refreshProfile } = useProfile();
  const classes = useStyles(props);
  const confirm = useConfirm();

  const { loaded, identity } = useGetIdentity();
  
  const notifyTechinicalError = useCallback(
    () => {
      notify(
        "A technical error occured while updating your profile. Please try later.",
        "warning",
        {
          _:
            "A technical error occured while updating your profile. Please try later."
        }
      );
    },
    [notify]
  );

  const updateUserProfile = useCallback(
    (values, needLogout) => {
      userServices.updateProfile(
        {
          fullName: values.fullName,
          email: values.newEmail,
          password: values.password,
          confirmPassword: values.confirmPassword,
          avatar: values.avatar || undefined,
          currentPassword: values.currentPassword,        
        }
      ).then(() => {
        setLoading(false);
        if(needLogout) {
          authProvider.logout();
          history.push('/')
        }
        else {
          dataProvider.updateUserProfile(
            { data: values },
            {
              onSuccess: ({ data }) => {
                setSaving(false);
                notify("Your profile has been updated", "info", {
                  _: "Your profile has been updated"
                });
                refreshProfile();
              },
              onFailure: () => {
                setSaving(false);
                notifyTechinicalError();
              }
            }
          );          
        }
      }).catch((error) => {
        setLoading(false);
        notifyTechinicalError();
      });
    },
    [notify, refreshProfile, dataProvider, authProvider, history, notifyTechinicalError]
  );

  const handleSaveAvatar = (values) => {
    setLoading(true);
    const { avatar } = values;
    
    if(!avatar){
      setLoading(false);
      return;
    }
    
    const { rawFile } = values.avatar;
    const data = new FormData();
    data.append('file', rawFile);

    userServices.updateProfilePhoto(data)
      .then(async (response) => {
        return updateUserProfile({...values,  avatar: response.data});
      }).catch(() => {
        setLoading(false);
        notifyTechinicalError();
      });
  }

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      
      if(values.newEmail || values.password) {
        updateUserProfile(values, true);
      }
      else {
        updateUserProfile(values, false);
      }
    },
    [updateUserProfile]
  );
  
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving
    }),
    [saving, handleSave]
  );

  const forgotPassword = () => {
    confirm({description: 'Are you want to send a reset password email?'})
    .then(() => {
      setLoading(true);

      userServices.resetPassword(identity.id)
        .then(() => {
          setLoading(false);
          notify(
            "A reset password email has been sent.",
            "success",
            {
              _:
                "A reset password email has been sent."
            }
          );
        }).catch(() => {
          setLoading(false);
          notify(
            "A technical error occured while reseting the password. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while reseting the password. Please try later."
            }
          );
        });
    });
  }

  const validate = (values) => {
    const errors = { password: undefined, confirmPassword: undefined, newEmail: undefined };

    if(values.confirmPassword && values.password) {
      if(values.password !== values.confirmPassword) {
        errors.password = 'Passwords need match';
        errors.confirmPassword = 'Passwords need match';
      }
    }

    if(values.newEmail) {
      errors.newEmail = validateEmail(values.newEmail);
    }
   
    return errors;
  };

  return !loaded || (
    <>
      <SanitizedGrid container spacing={0}>
        <SanitizedGrid container spacing={2}>
          <SaveContextProvider value={saveContext}>
            <SimpleForm
              save={handleSave}
              record={identity || {}}
              validate={validate}
            >
              <SanitizedGrid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextInput source="fullName" fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                    <div class={classes.changeEmail}>
                      <p>
                        Change Email
                      </p>
                      <p style={{fontWeight: 'bold'}}>
                        {identity.email}
                      </p>
                    </div>
                    <TextInput source="newEmail" label="Email" type="email" fullWidth/>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextInput source="password" type="password" fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput source="confirmPassword" type="password" fullWidth/>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'end'}}>
                    <Grid item xs={12}>
                      <TextInput source="currentPassword" type="password" validate={required()} fullWidth/>
                    </Grid>
                  </Grid>
                </Grid>
              </SanitizedGrid>
            </SimpleForm>
          </SaveContextProvider>
        </SanitizedGrid>

        <SanitizedGrid container spacing={0}>

          <Grid item xs={8}>
            <SimpleForm
              save={handleSaveAvatar}
            >
              <ImageInput source="avatar" label="Upload profile photo" accept="image/*"  >
                <ImageField source="src" />
              </ImageInput>
              <TextInput style={{ marginTop: 18, width: 479 }} source="currentPassword" type="password" validate={required()} fullWidth/>
            </SimpleForm>
          </Grid>

        </SanitizedGrid>

      </SanitizedGrid>

      <SanitizedGrid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <Link
              disabled={loading}
              component="button"
              variant="body1"
              onClick={forgotPassword}
            >
              Forgot Password?
            </Link>
          </Typography>
        </Grid>
      </SanitizedGrid>
    </>
  );
};
