import React, { useEffect, useState } from 'react';
import { AutocompleteInput, DateInput, SelectInput, Filter } from 'react-admin';
import { proposalStatus } from '../../config/constants';
import { userServices } from '../../services/user';
import { startCase, uniqBy } from 'lodash'
import { formatDate } from '../../utils/helpers';

const Filters = (props) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
      (async () => {
        const result = (await userServices.getAll()).filter(user => user.id)
        setUsers(result)
      })()
    }, [])

  return (
    <Filter {...props}>
        <SelectInput source="status" choices={[
          { id: proposalStatus.DRAFT, name: 'Draft' },
          { id: proposalStatus.COMPLETE, name: 'Complete' },
          { id: proposalStatus.SOLD, name: 'Sold' },
          { id: proposalStatus.DECLINED, name: 'Declined' },
          { id: proposalStatus.INVALID, name: 'Invalid' },
        ]} />
        <div label="Effective Date" source="effectiveDateStart">
        <DateInput
            format={formatDate}
            helperText={false}
            source="effectiveDateStart"
            label="Start Effective Date"
            autoComplete="off"
        />
        <DateInput
            format={formatDate}
            helperText={false}
            source="effectiveDateEnd"
            label="End Effective Date"
            style={{ marginLeft: 10 }}
            autoComplete="off"
        />
        </div>
        <AutocompleteInput
            optionText={value => value?.fullName ? `${startCase(value.fullName)}`: "None" }
            optionValue={"fullName"}
            source={'user.fullName'}
            label={'Full Name'}
            choices={uniqBy(users, 'email') || []}
        />
        <AutocompleteInput
            optionText={value => value?.title ? `${startCase(value.title)}` : "None" }
            optionValue={"title"}
            source={'user.title'}
            label={'Title'}
            choices={uniqBy(users.filter((user) => user.title), 'title') || []}
        />
        <AutocompleteInput
            optionText={value => value?.type ? `${startCase(value.type)}` : "None" }
            optionValue={"type"}
            source={'user.type'}
            label={'Type'}
            choices={uniqBy(users.filter((user) => user.type), 'type') || []}
        />
        <div label="Created At" source="createdAtStart">
          <DateInput
            format={formatDate}
            helperText={false}
            source="createdAtStart"
            label="Start Created At"
            autoComplete="off"
          />
          <DateInput
            format={formatDate}
            helperText={false}
            source="createdAtEnd"
            label="End Created At"
            style={{ marginLeft: 10 }}
            autoComplete="off"
          />
          </div>
    </Filter>
  );
}

export default Filters;