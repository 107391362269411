import React, { useEffect } from "react";
import {
  AutocompleteInput,
  TextInput,
  DateInput,
  SelectInput,
  Filter,
} from "react-admin";
import { proposalStatus } from "../config/constants";
import { startCase } from "lodash";
import { formatDate } from "./helpers";

const Filters = ({
  users,
  setFilters,
  displayedFilters,
  filterValues,
  ...props
}) => {
  useEffect(() => {
    async function fetchData() {
      if (
        !displayedFilters.effectiveDateStart &&
        filterValues.effectiveDateEnd &&
        setFilters
      ) {
        setFilters({ ...filterValues, effectiveDateEnd: null });
      }
    }
    fetchData();
  }, [displayedFilters, filterValues, setFilters]);

  return (
    <Filter {...props}>
      <TextInput label="Company name" source="company.name" />
      <TextInput label="Contact name" source="company.contactName" />
      <TextInput label="Email" source="company.contactEmail" type={"email"} />
      <TextInput label="ZIP" source="company.zip" type="number" />
      <TextInput label="Broker Name" source="brokerName" />
      <div label="Effective Date" source="effectiveDateStart">
        <DateInput
          format={formatDate}
          helperText={false}
          source="effectiveDateStart"
          label="Start Effective Date"
          autoComplete="off"
        />
        <DateInput
          format={formatDate}
          helperText={false}
          source="effectiveDateEnd"
          label="End Effective Date"
          style={{ marginLeft: 10 }}
          autoComplete="off"
        />
      </div>
      <div label="Last Modified At" source="lastModifiedStart">
        <DateInput
          format={formatDate}
          helperText={false}
          source="lastModifiedStart"
          label="Start Last Modified"
          autoComplete="off"
        />
        <DateInput
          format={formatDate}
          helperText={false}
          source="lastModifiedEnd"
          label="End Last Modified"
          style={{ marginLeft: 10 }}
          autoComplete="off"
        />
      </div>
      <AutocompleteInput
        optionText={(value) =>
          value?.fullName ? `${startCase(value.fullName)} - ${value.email}` : ""
        }
        source={"userId"}
        label={"Sales Representative"}
        choices={users || []}
      />
      <SelectInput
        source="status"
        choices={[
          { id: proposalStatus.DRAFT, name: "Draft" },
          { id: proposalStatus.COMPLETE, name: "Complete" },
          { id: proposalStatus.SOLD, name: "Sold" },
          { id: proposalStatus.DECLINED, name: "Declined" },
          { id: proposalStatus.INVALID, name: "Invalid" },
        ]}
      />
      <TextInput label="Updated by" source="updatedBy.email" />
      <TextInput label="Created by" source="createdBy.email" />
      <div label="Created At" source="createdAtStart">
        <DateInput
          format={formatDate}
          helperText={false}
          source="createdAtStart"
          label="Start Created At"
          autoComplete="off"
        />
        <DateInput
          format={formatDate}
          helperText={false}
          source="createdAtEnd"
          label="End Created At"
          style={{ marginLeft: 10 }}
          autoComplete="off"
        />
      </div>
    </Filter>
  );
};

export default Filters;
