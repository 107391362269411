import {http} from "../config/service";

export const loginService = {
  performLogin: async (email, password) =>
    http.post('/user/login', {email, password}).then(res=>res?.data),

  me: async token =>
    http.get('/user/me', { headers: { Authorization: `Bearer ${token}` } }).then(res=>res?.data),

  cachedMe: () => JSON.parse(localStorage.getItem('me'))
}