import moment from 'moment';
import { array, object, func, bool } from 'prop-types'
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { useEffect, useState } from "react";
import { orderPlans } from './plansHelper';
import { difference, groupBy, includes, map, omit } from 'lodash'
import { groupOrder, groupWithOneCorrectOption, groupWithoutAllSelect } from '../../../constants/plans';

const PlansCheckboxGroup = (allProps) => {
  const { plans, onChange, selected: selectedFromProps, effectiveDate } = allProps;

  const [selectAll, setSelectAll] = useState([])
  const [plansToList, setPlansToList] = useState([])
  const [selected, setSelected] = useState(selectedFromProps || [])

  const effectiveDateYear = moment(effectiveDate).year()

  useEffect(() => {
    const allGroupedPlans = groupBy(orderPlans(plans, effectiveDateYear), 'group')

    setPlansToList(allGroupedPlans)
  }, [plans, effectiveDate, effectiveDateYear])

  useEffect(() => {
    onChange(selected)
  }, [selected, onChange])

  useEffect(() => {
    if (plans?.length > 0 && selected?.length > 0) {
      for (let i = 0; i < selected.length; i += 1) {
        if (plans?.findIndex((item) => item.id === selected[i]) === -1) {
          setSelected([])
        }
      }
    }
  }, [plans, selected])

  const onlyOneCorrectOption = (groupName) => 
    groupWithOneCorrectOption[effectiveDateYear]?.includes(groupName)

  const sortGroup = (groupedPlans) => 
    groupedPlans.sort((curr, next) => 
      groupOrder.indexOf(curr) - groupOrder.indexOf(next))
  
  const toggleChangeAll = group => {
    let selectAllCopy = [...selectAll]
    if (selectAllCopy.includes(group)) {
      selectAllCopy = selectAllCopy.filter(select => select !== group)
      const selectedWithoutGroup = selected.filter(selected =>
        !plans
          .filter(plan => plan.group === group)
          .find(plan => plan.id === selected)
      )
      setSelected(selectedWithoutGroup)
    } else {
      selectAllCopy.push(group)
      setSelected([
        ...selected,
        ...plans
          .filter(plan => group === plan.group)
          .map(plan => plan.id)
      ])
    }
    setSelectAll(selectAllCopy)
  }

  const toggleChange = id => {
    let selectedCopy = [...selected]
    if (selectedCopy.includes(id))
      selectedCopy = selectedCopy.filter(select => select !== id)
    else
      selectedCopy.push(id)
    setSelected(selectedCopy)
  }

  const toggleChangeRadio = (id, groupName) => {
    let selectedCopy = [...selected]
    if (includes(selectedCopy, id)) {
      selectedCopy = omit(selectedCopy, id)
    } else {
      const idsOfTheSameGroup = map(plansToList[groupName], 'id')
      selectedCopy = difference(selectedCopy, idsOfTheSameGroup);
      selectedCopy.push(id)
    }
    setSelected(selectedCopy)
  }

  const resetRadioGroup = (groupName) => {
    let selectedCopy = [...selected]
    const idsOfTheSameGroup = map(plansToList[groupName], 'id')
    selectedCopy = difference(selectedCopy, idsOfTheSameGroup);
    setSelected(selectedCopy)
  }

  const hiddenCheckboxForSelectAll= (groupName) => {
    return groupWithoutAllSelect[effectiveDateYear]?.includes(groupName)
  }

  return (
    <div className={'noLineHeight'}>
      {sortGroup(Object.keys(plansToList)).map((groupName) => !!plansToList[groupName].length && (
        <div key={groupName}>

          {/* Group title */}
          {hiddenCheckboxForSelectAll(plansToList[groupName][0]?.group) ?
            <FormLabel className={'fullWidth'}>
              {plansToList[groupName][0]?.group || ''}
            </FormLabel>
          :
            <FormControlLabel
              key={plansToList[groupName].length}
              className={'fullWidth'}
              control={<Checkbox checked={selectAll.includes(plansToList[groupName][0]?.group)}
              onChange={_ => toggleChangeAll(plansToList[groupName][0]?.group)} />}
              label={`All ${plansToList[groupName][0]?.group || ''}`}
            />
          }

          {/* Options */}
          {onlyOneCorrectOption(groupName) ?
            <>
              <RadioGroup>
                {plansToList[groupName]?.map(({ id, name }) => (
                  <FormControlLabel
                    key={name}
                    label={name}
                    control={<Radio 
                      checked={selected?.includes(id)} 
                      onChange={() => toggleChangeRadio(id, groupName)} 
                      onClick={() => resetRadioGroup(groupName)} />}
                  /> 
                ))}
              </RadioGroup>
            </>
          :
            plansToList[groupName]?.map(({ id, name }) => (
              <FormControlLabel
                key={name}
                label={name}
                control={<Checkbox 
                  checked={selected?.includes(id)} 
                  onChange={() => toggleChange(id)} />}
              /> 
            ))
          }

        </div>
      ))
    }

      {!selected.length && <p className={'MuiFormHelperText-root Mui-error'}>You should select at least one plan</p>}
    </div>
  )
};

PlansCheckboxGroup.propTypes = {
  plans: array,
  record: object,
  onChange: func,
  selected: bool,
  fullWidth: bool
};

export default PlansCheckboxGroup;
