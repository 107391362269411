export const order = {
  2021: [
    "PPO HSA 1450",
    "PPO HSA 1900",
    "PPO HSA 2900",
    "PPO HSA 3700",
    "PPO HSA 4900",
    "PPO HSA 6000",
    "PPO 10/0",
    "PPO 20/600",
    "PPO 20/1000",
    "PPO 30/600",
    "PPO 35/1200",
    "PPO 40/2000",
    "PPO 45/1500",
    "PPO 45/2500",
    "PPO 50/6000 Saver",
    "PPO 65/3900",
    "HMO 10/0",
    "HMO 35/0",
    "HMO 25/1500",
    "HMO 30/3000",
    "Select PPO HSA 1450",
    "Select PPO HSA 1900",
    "Select PPO HSA 2900",
    "Select PPO HSA 3700",
    "Select PPO HSA 4900",
    "Select PPO HSA 6000",
    "Select PPO 10/0",
    "Select PPO 20/600",
    "Select PPO 20/1000",
    "Select PPO 30/600",
    "Select PPO 35/1200",
    "Select PPO 40/2000",
    "Select PPO 45/1500",
    "Select PPO 45/2500",
    "Select PPO 50/6000 Saver",
    "Select PPO 65/3900",
    "Select HMO 10/0",
    "Select HMO 35/0",
    "Select HMO 25/1500",
    "Select HMO 30/3000",
    "Dental",
    "Vision",
    "Life & LTD",
  ],
  2022: [
    "PPO HSA 1450",
    "PPO HSA 1950",
    "PPO HSA 2950",
    "PPO HSA 3750",
    "PPO HSA 4950",
    "PPO HSA 6250",
    "Select PPO HSA 1450",
    "Select PPO HSA 1950",
    "Select PPO HSA 2950",
    "Select PPO HSA 3750",
    "Select PPO HSA 4950",
    "Select PPO HSA 6250",
    "PPO 10/0",
    "PPO 25/750",
    "PPO 25/1000",
    "PPO 30/650",
    "PPO 35/1250",
    "PPO 40/2000",
    "PPO 45/1650/OV-1",
    "PPO 45/2750/OV-1",
    "PPO 50/2250",
    "PPO 50/6000/OV-3",
    "PPO 65/3950",
    "PPO 75/7000",
    "Select PPO 10/0",
    "Select PPO 25/750",
    "Select PPO 25/1000",
    "Select PPO 30/650",
    "Select PPO 35/1250",
    "Select PPO 40/2000",
    "Select PPO 45/1650/OV-1",
    "Select PPO 45/2750/OV-1",
    "Select PPO 50/2250",
    "Select PPO 50/6000/OV-3",
    "Select PPO 65/3950",
    "Select PPO 75/7000",
    "HMO 10/0",
    "HMO 35/0",
    "HMO 25/1500",
    "HMO 30/3000",
    "Select HMO 10/0",
    "Select HMO 35/0",
    "Select HMO 25/1500",
    "Select HMO 30/3000",
    "Dental",
    "Vision",
    "Life & LTD",
  ],
  2023: [
    "PPO HSA 1500",
    "PPO HSA 2000",
    "PPO HSA 3000",
    "PPO HSA 3850",
    "PPO HSA 5000",
    "PPO HSA 6350",
    "EPO HSA 3000",
    "Select PPO HSA 1500",
    "Select PPO HSA 2000",
    "Select PPO HSA 3000",
    "Select PPO HSA 3850",
    "Select PPO HSA 5000",
    "Select PPO HSA 6350",
    "PPO 10/0",
    "PPO 30/650",
    "PPO 25/750",
    "PPO 30/1000",
    "PPO 35/1250",
    "PPO 45/1850",
    "PPO 45/2250",
    "PPO 50/2500",
    "PPO 45/2850",
    "PPO 65/4250",
    "PPO 50/6250/OV-3",
    "PPO 75/7250",
    "EPO 25/750",
    "EPO 50/2500",
    "Select PPO 10/0",
    "Select PPO 30/650",
    "Select PPO 25/750",
    "Select PPO 30/1000",
    "Select PPO 35/1250",
    "Select PPO 45/1850",
    "Select PPO 45/2250",
    "Select PPO 50/2500",
    "Select PPO 45/2850",
    "Select PPO 65/4250",
    "Select PPO 50/6250/OV-3",
    "Select PPO 75/7250",
    "HMO 10/0",
    "HMO 35/0",
    "HMO 25/1500",
    "HMO 30/3000",
    "Select HMO 10/0",
    "Select HMO 35/0",
    "Select HMO 25/1500",
    "Select HMO 30/3000",
    "Ancillary Plans - Direct",
    "Ancillary Plans - Broker"
  ],
  2024: [
    "PPO HSA PRx 1600",
    "PPO HSA PRx 1800",
    "PPO HSA PRx 2000",
    "PPO HSA PRx 3000",
    "PPO HSA PRx 3900",
    "PPO HSA PRx 5000",
    "PPO HSA PRx 6350",
    "EPO HSA PRx 3000",
    "Select PPO HSA PRx 1600",
    "Select PPO HSA PRx 1800",
    "Select PPO HSA PRx 2000",
    "Select PPO HSA PRx 3000",
    "Select PPO HSA PRx 3900",
    "Select PPO HSA PRx 5000",
    "Select PPO HSA PRx 6350",
    "PPO 10/0",
    "PPO 30/650",
    "PPO 25/750",
    "PPO 30/1000",
    "PPO 30/1250",
    "PPO 45/1850",
    "PPO 45/2250",
    "PPO 50/2500",
    "PPO 45/2850",
    "PPO 65/4250",
    "PPO 50/6250/OV-3",
    "PPO 75/7250",
    "EPO 25/750",
    "EPO 50/2500",
    "Select PPO 10/0",
    "Select PPO 30/650",
    "Select PPO 25/750",
    "Select PPO 30/1000",
    "Select PPO 30/1250",
    "Select PPO 45/1850",
    "Select PPO 45/2250",
    "Select PPO 50/2500",
    "Select PPO 45/2850",
    "Select PPO 65/4250",
    "Select PPO 50/6250/OV-3",
    "Select PPO 75/7250",
    "HMO 10/0",
    "HMO 35/0",
    "HMO 25/1500",
    "HMO 30/3000",
    "Select HMO 10/0",
    "Select HMO 35/0",
    "Select HMO 25/1500",
    "Select HMO 30/3000",
    "Ancillary Plans - Direct",
    "Ancillary Plans - Broker"
  ],
  2025: [
    'PPO HSA PRx 1650',
    'PPO HSA PRx 1850',
    'PPO HSA PRx 2000',
    'PPO HSA PRx 3000',
    'PPO HSA PRx 3900',
    'PPO HSA PRx 5000',
    'PPO HSA PRx 6500',
    'EPO HSA PRx 3000',
    'Select PPO HSA PRx 1650',
    'Select PPO HSA PRx 1850',
    'Select PPO HSA PRx 2000',
    'Select PPO HSA PRx 3000',
    'Select PPO HSA PRx 3900',
    'Select PPO HSA PRx 5000',
    'Select PPO HSA PRx 6500',
    'PPO 10/0',
    'PPO 25/750',
    'PPO 30/1000',
    'PPO 30/1250',
    'PPO 45/1850',
    'PPO 45/2250',
    'PPO 45/2850',
    'PPO 65/4250',
    'PPO 50/6250/OV-3',
    'PPO 75/7250/OV-1',
    'EPO 25/750',
    'EPO 45/2850',
    'Select PPO 10/0',
    'Select PPO 25/750',
    'Select PPO 30/1000',
    'Select PPO 30/1250',
    'Select PPO 45/1850',
    'Select PPO 45/2250',
    'Select PPO 45/2850',
    'Select PPO 65/4250',
    'Select PPO 50/6250/OV-3',
    'Select PPO 75/7250/OV-1',
    'HMO 10/0',
    'HMO 35/0',
    'HMO 25/1500',
    'HMO 30/3000',
    'Select HMO 10/0',
    'Select HMO 35/0',
    'Select HMO 25/1500',
    'Select HMO 30/3000',
    'Ancillary Plans - Direct',
    'Ancillary Plans - Broker',
  ]
};

export const groupOrder = [
  "HSA",
  "PPO HSA",
  "EPO HSA",
  "HSA Select",
  "Select PPO HSA",
  "PPO Copay",
  "EPO Copay",
  "Select PPO Copay",
  "HMO",
  "HMO Select",
  "Select HMO",
  "Ancillary Plans",
  "Stand Alone Ancillary",
  "null",
];


export const groupWithoutAllSelect = {
  2023: [
    "Ancillary Plans"
  ],
  2024: [
    "Stand Alone Ancillary"
  ],
  2025: [
    "Stand Alone Ancillary"
  ],
}


export const ancillaryPlansToDownloadSeparated = {
  2023: [
    "Ancillary Plans - Direct",
    "Ancillary Plans - Broker"
  ],
  2024: [
    "Ancillary Plans - Direct",
    "Ancillary Plans - Broker"
  ],
  2025: [
    "Ancillary Plans - Direct",
    "Ancillary Plans - Broker"
  ],
}

export const groupWithOneCorrectOption = {
  2023: [
    "Ancillary Plans"
  ],
  2024: [
    "Stand Alone Ancillary"
  ],
  2025: [
    "Stand Alone Ancillary"
  ],
}
