import {
  AutocompleteInput,
  DateInput,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  FormDataConsumer
} from "react-admin";
import { useForm } from 'react-final-form'
import { object,bool,func } from "prop-types";
import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { planServices } from "../../../services/plan";
import { userServices } from "../../../services/user";
import { FormToolbar } from "../../../utils/FormToolbar";
import { formatDate, validateEmail } from "../../../utils/helpers";
import { loginService } from "../../../services/login";
import PlansCheckboxGroup from "../utils/PlansCheckboxGroup";
import InputMask from "react-input-mask";
import GroupLevel  from "./GroupLevel";
import moment from "moment";

const ProposalBasicModal = ({  
  record,
  setPhone,
  plans,
  me, 
  users,
  selectedPlans,
  setSelectedPlans,
  setEffectiveDate,
  setGroupLevel,
  effectiveDate
}) => {
  const form = useForm();
  const [openAlertLevelGroupChange,setOpenAlertLevelGroupChange ] = useState(false)

  const switchLevelGroup = ({ groupLevel, employeeCensus }) => {
    form.change('groupLevel', 'm1')
    if(groupLevel === 'm1' && employeeCensus?.length > 1) {
      form.change('employeeCensus', [employeeCensus[0]]);
    }
    setOpenAlertLevelGroupChange(false)
  }

  const onCancelSwitchLevelGroup = () => {
    form.change('groupLevel', 'm2')
    setOpenAlertLevelGroupChange(false)
  }

  const onChangeGroupLevel = (formData) => {
    setGroupLevel(form.getState().values.groupLevel)
    formData.groupLevel === 'm2' 
      && formData.employeeCensus?.length > 1 
      && setOpenAlertLevelGroupChange(true)
  }

  return (
    <>
      <GroupLevel
        openAlertLevelGroupChange={openAlertLevelGroupChange} 
        setOpenAlertLevelGroupChange={setOpenAlertLevelGroupChange}
        onOk={switchLevelGroup}
        onCancel={onCancelSwitchLevelGroup}
      />
      <TextInput source={'company.name'} label="Name" fullWidth validate={[required()]}/>
      <TextInput source={'company.address'} label="Address" fullWidth validate={[required()]}/>
      <TextInput source={'company.city'} label="City" fullWidth validate={[required()]}/>
      <TextInput source={'company.zip'} label="ZIP" type={'number'} validate={[required()]}/>
      <DateInput source={'effectiveDate'} format={formatDate} autoComplete="off" defaultValue={null} validate={[required()]} onChange={(e) => {
        setSelectedPlans([])
        setEffectiveDate(moment(e.target.value).toDate())
      }}/>
      <TextInput source={'company.contactName'} label={'Contact name'} fullWidth/>
      <TextInput source={'company.contactEmail'} label={'Email'} fullWidth/>
      <InputMask
        defaultValue={record?.company?.contactPhone || ''}
        mask="999-999-9999"
        disabled={false}
        maskChar=" "
        onChange={e => setPhone(e.target.value?.replace(/[^0-9]/g, ''))}
      >
        {() => <TextField id="company.contactPhone" variant="filled" label={'Phone'} />}
      </InputMask>
      <AutocompleteInput
        optionText={value => value?.fullName || ''}
        source={'userId'}
        label={'Sales Representative'}
        disabled={!me || me.type === "sales"}
        choices={users}
        fullWidth
      />
      <TextInput source={'brokerName'} fullWidth/>
      <TextInput source={'agency'} fullWidth/>
      <FormDataConsumer>
        {({ formData }) => (
          <SelectInput 
            source={'groupLevel'} 
            choices={[
              { id: 'm1', name: 'M1' },
              { id: 'm2', name: 'M2' },
            ]}    
            onChange={() => onChangeGroupLevel(formData)}
          />
        )}
      </FormDataConsumer>
      <PlansCheckboxGroup
        key={effectiveDate}
        plans={plans}
        selected={selectedPlans?.map((item) => item?.id || item)}
        onChange={setSelectedPlans}
        effectiveDate={effectiveDate}
        fullWidth
      />
    </>
  )
}

const Wrapper = ({record, open, setOpen, setRecord, setNeedSimulate}) => {
  const [me, setMe] = useState(null)
  const [users, setUsers] = useState([])
  const [phone, setPhone] = useState('')
  const [plans, setPlans] = useState([])
  const [selectedPlans, setSelectedPlans] = useState(record.plans || [])
  const [effectiveDate, setEffectiveDate] = useState(record.effectiveDate)
  const [groupLevel, setGroupLevel] = useState(record.groupLevel)

  useEffect(() => {
    async function fetchData() {
      if(effectiveDate) {
        setPlans(await planServices.getValidPlans(effectiveDate, groupLevel))
      }
    }
    fetchData();
  }, [effectiveDate, groupLevel]);

  useEffect(() => {
    async function fetchData() {
      setUsers(await userServices.getAll())
      setMe(await loginService.me(localStorage.getItem('token')))
    }
    fetchData();
  }, []);


  const validate = (values) => {
    const errors = {
      company: {
        contactEmail: undefined,
        contactName: undefined
      }
    }

    if(values.company?.contactEmail) {
      errors.company.contactEmail = validateEmail(values.company.contactEmail);
    }

    if(values.company?.contactName) {
      const contactNameArray = values.company.contactName.split(' ');
      errors.company.contactName = contactNameArray.length <= 1
        ? 'Insert a name and last name'
        : contactNameArray[contactNameArray.length - 1] === '' ? 'Insert a name and last name' : undefined;
    }

    if(!selectedPlans.length)
      errors.plans = 'You should select a least one plan'

    return errors;
  }

  const save = data => {
    record.company.contactPhone = phone;
    data.company.contactPhone = phone;
    setRecord({
      ...record,
      ...data,
      plans: selectedPlans.map(id=>plans.find(plan=>plan.id===id))
    })
    setNeedSimulate(true)
    setOpen(false)
  }

  const sanitizedRecord = {
    ...record,
    plans: record.plans?.map(plan=>plan.id)
  }

  return (
    <Dialog fullWidth onClose={_=>setOpen(false)} open={open}>
      <DialogTitle>
        Edit company
      </DialogTitle>
      <DialogContent>
        <SimpleForm 
          validate={validate} 
          save={save} 
          record={sanitizedRecord} 
          toolbar={<FormToolbar onClose={_ => setOpen(false)} />}
        >
          <ProposalBasicModal
            record={record} 
            setPhone={setPhone}
            plans={plans}
            me={me}
            users={users}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            setEffectiveDate={setEffectiveDate}
            setGroupLevel={setGroupLevel}
            effectiveDate={effectiveDate}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

Wrapper.propTypes = {
  record: object,
  open: bool,
  setOpen: func,
  setRecord: func,
  setNeedSimulate: func
}

export default Wrapper