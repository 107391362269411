import React, { useState, useEffect } from "react";
import {Dialog, DialogContent, DialogTitle, InputAdornment, Tooltip} from "@material-ui/core";
import {
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  FormDataConsumer
} from "react-admin";
import {bool, func, object, number} from "prop-types";
import {FormToolbar} from "../../../utils/FormToolbar";
import {formatDate, validateAge, validateDate} from "../../../utils/helpers";
import { HelpOutline } from "@material-ui/icons";
import { pick } from "lodash"

const EmployeeModal = ({ record }) => {
  const [disableBirthdate, setDisableBirthdate] = useState(false);
  const [disableAge, setDisableAge] = useState(false);
  const [disableSpouseBirthdate, setDisableSpouseBirthdate] = useState(false);
  const [disableSpouseAge, setDisableSpouseAge] = useState(false);

  useEffect(() => {
    if(record?.birthdate) {
      setDisableAge(true)
      setDisableBirthdate(false)
    } 

    if(record?.age) {
      setDisableAge(false)
      setDisableBirthdate(true)
    }

    if(record?.spouseBirthdate) {
      setDisableSpouseAge(true)
      setDisableSpouseBirthdate(false)
    } 
    
    if(record?.spouseAge) {
      setDisableSpouseAge(false)
      setDisableSpouseBirthdate(true)
    }
  }, [record])

  return (
    <>  
     <TextInput source={'name'} fullWidth validate={[required()]}/>
      <TextInput source={'zip'} type={'number'} validate={[required()]}/>
      <div style={{ display: 'flex'}}>
      <DateInput
        format={formatDate}
        source={'birthdate'}
        validate={[validateDate]}
        label={'Birthdate'}
        autoComplete="off"
        disabled={disableBirthdate}
        onChange={(e) => {
          if (e.target.value === '') {
            setDisableAge(false);
          } else {
            setDisableAge(true);
          }
        }}
        />
      <span style={{ margin: '0 10px', width: '21px'}}> or </span>
      <NumberInput
        source={'age'}
        style={{width: 100}}
        label={'Age'}
        disabled={disableAge}
        validate={[validateAge]}
        onChange={(e) => {
          if (e.target.value === '' || Number(e.target.value) === 0) {
            setDisableBirthdate(false);
          } else {
            setDisableBirthdate(true);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title='If entering age, state age at the time of the effective date.'>
                <HelpOutline />
              </Tooltip>
            </InputAdornment>
          )
        }}
        />
        </div>
      <DateInput
        validate={[validateDate]}
        format={formatDate}
        source={'spouseBirthdate'}
        label={'Spouse birthdate'}
        autoComplete="off"
        disabled={disableSpouseBirthdate}
        onChange={(e) => {
          if (e.target.value === '') {
            setDisableSpouseAge(false);
          } else {
            setDisableSpouseAge(true);
          }
        }}
      />
      <span style={{ margin: '0 10px', width: '21px'}}> or </span>
      <NumberInput
        source={'spouseAge'}
        validate={[validateAge]}
        style={{width: 130}}
        label={'Spouse age'}
        disabled={disableSpouseAge}
        onChange={(e) => {
          if (e.target.value === '' || Number(e.target.value) === 0) {
            setDisableSpouseBirthdate(false);
          } else {
            setDisableSpouseBirthdate(true);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title='If entering age, state age at the time of the effective date.'>
                <HelpOutline />
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
      <ArrayInput source={'children'} label={'Children'} id={'children'}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({
              scopedFormData,
              getSource,
              ...rest
            }) =>
              <DateInput
                source={getSource('birthdate')}
                {...rest}
                format={formatDate}
                validate={[validateDate]}
                label={'Child birthdate'}
                style={{width: 200}}
                autoComplete="off"
                disabled={scopedFormData?.age}
              />
            }
          </FormDataConsumer>
          <span style={{ margin: '0 10px' }}> or </span>
          <FormDataConsumer>
            {({
              scopedFormData,
              getSource,
              ...rest
            }) =>
              <NumberInput
                source={getSource('age')}
                {...rest}
                validate={[validateAge]}
                style={{width: 130}}
                label={'Child age'}
                disabled={scopedFormData?.birthdate}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title='If entering age, state age at the time of the effective date.'>
                        <HelpOutline />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

EmployeeModal.propTypes = {
  record: object
}

const Wrapper = ({record, open, setOpen, saveEmployee, index, setNeedSimulate}) => {

  const save = form => {
    const employee = pick(form, [
      'name', 
      'zip', 
      'birthdate', 
      'age', 
      'spouseBirthdate', 
      'spouseAge', 
      'children'
    ])
    saveEmployee(employee, index)
    setNeedSimulate(true)
    setOpen(false)
  }

  const validate = (values) => {
    const errors = {
      age: undefined,
      birthdate: undefined,
      children: []
    };

    if (!values.birthdate && !values.age) {
      errors.birthdate = 'At last one is required';
      errors.age = 'At last one is required';
    }

    for (let i = 0; i < values.children?.length; i += 1) {
      if (!values.children[i]?.birthdate && !values.children[i]?.age) {
        errors.children[i] = {
          age: 'At last one is required',
          birthdate: 'At last one is required'
        }
      }
    }

    return errors;
  }

  return (
    <Dialog fullWidth onClose={_ => setOpen(false)} open={open}>
      <DialogTitle>
        {record ? 'Edit' : 'New'} employee
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          save={save}
          record={record}
          validate={validate}
          toolbar={<FormToolbar onClose={_ => setOpen(false)}/>}>
          <EmployeeModal record={record}/>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

Wrapper.propTypes = {
  record: object,
  open: bool,
  setOpen: func,
  saveEmployee: func,
  index: number,
  setNeedSimulate: func
}

export default Wrapper