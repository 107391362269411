import React, { useState } from 'react';
import { List, Datagrid, TextField, FunctionField, useRefresh, useNotify } from 'react-admin';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import { Select, MenuItem, Button } from '@material-ui/core';
import UserModal from '../form/modals/User';
import { useConfirm } from "material-ui-confirm";
import { userServices } from '../../services/user';
import UserInviteList from './UserInviteList';

const UserList = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const confirm = useConfirm();

  const handleUpdate = async record => {
    await setUserInfo(record);
    setOpen(true);
  }
  const handleDelete = record => {
    confirm({ description: `Are you sure you want to delete the user: ${record.fullName}?` })
      .then(() => {
        userServices.delete(record.id).then(() => {
          refresh();
          notify(
            'User deleted.',
            "success",
            {
              _:
                'User deleted.'
            }
          );
        }).catch(() => {
          setLoading(false);
          notify(
            "A technical error occured while deleting the user. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while deleting the user. Please try later."
            }
          );
        });
      });
  }

  const handleTypeAdmin = record => updateType('admin', record.id);
  const handleTypeAgent = record => updateType('agent', record.id);
  const handleTypeSales = record => updateType('sales', record.id);

  const handleType = (action, record) => {
    const handlers = {
      'admin': handleTypeAdmin,
      'agent': handleTypeAgent,
      'sales': handleTypeSales
    }

    return handlers[action](record);
  }

  const handleAction = (action, record) => {
    const handlers = {
      'update': handleUpdate,
      'delete': handleDelete,
    }

    return handlers[action](record);
  }

  const updateType = (type, id) => {
    confirm({ description: `Are you sure you want to change the user type to ${type}?` })
      .then(() => {
        userServices.update(id, { type }).then(() => {
          refresh();
          notify(
            `User type updated to ${type}.`,
            "success",
            {
              _:
                `User type updated to ${type}.`
            }
          );
        }).catch(() => {
          setLoading(false);
          notify(
            "A technical error occured while updating the user type. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while rupdating the user type. Please try later."
            }
          );
        });
      });
  }

  const resetPassword = (record) => {
    confirm({ description: 'Are you sure you want to reset the user password?' })
      .then(() => {
        setLoading(true);

        userServices.resetPassword(record.id).then(() => {
          setLoading(false);
          notify(
            "A reset password email has been sent.",
            "success",
            {
              _:
                "A reset password email has been sent."
            }
          );
        }).catch(() => {
          setLoading(false);
          notify(
            "A technical error occured while reseting the password. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while reseting the password. Please try later."
            }
          );
        });
      });
  }
  const [mode, setMode] = useState(/** @type {'users' | 'userInvites'} */('users'));

  return (
    <>
      <UserModal
        record={userInfo}
        open={open}
        setOpen={setOpen}
        refresh={refresh}
      />
      <FormControl component="fieldset" style={{ width: 200, position: 'relative', bottom: -60 }}>
        <FormLabel component="legend">List</FormLabel>
        <RadioGroup row aria-label="list" name="list" value={mode} onChange={e => setMode(e.target.value)}>
          <FormControlLabel value="users" control={<Radio />} label="Users" />
          <FormControlLabel value="userInvites" control={<Radio />} label="Invites" />
        </RadioGroup>
      </FormControl>
      <UserInviteList show={mode === 'userInvites'} />
      {mode === 'users' && (
        <List {...props} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
          <Datagrid >
            <TextField source="id" />
            <TextField source="email" emptyText="-" />
            <TextField source="title" emptyText="-" />
            <TextField source="fullName" />
            <FunctionField label="Roles" render={record => (
              <Select value={record.type} disabled={record.type === 'placeholder'} onChange={e => handleType(e.target.value, record)}>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem disabled value="placeholder">Placeholder</MenuItem>
              </Select>
            )} />
            <FunctionField render={record => (
              <Button variant="contained" disabled={loading || !record.email} onClick={() => { resetPassword(record) }}>
                Reset Password
              </Button>
            )} />
            <FunctionField render={record =>
              <Select value={0} onChange={e => handleAction(e.target.value, record)}>
                <MenuItem disabled value={0}>Actions</MenuItem>
                <MenuItem value="update">Update</MenuItem>
                <MenuItem value="delete">Delete</MenuItem>
              </Select>
            } />
          </Datagrid>
        </List>
      )}
    </>
  )
}

export default UserList;