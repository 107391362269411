import { uniqBy } from "lodash";
import { order } from "../../../constants/plans";

export const orderPlans = (plans, year) => {
	if(year in order) {
		return uniqBy(plans.sort((curr, next) =>  
			order[year].indexOf(curr.name) - order[year].indexOf(next.name)
		), 'name')
	} else {
		const orderKeys = Object.keys(order)
		const lastYear =  orderKeys[orderKeys.length - 1]

		return uniqBy(plans.sort((curr, next) =>  
			order[lastYear].indexOf(curr.name) - order[lastYear].indexOf(next.name)
		), 'name')
	}
}