// @ts-check

import React from 'react';
import { TextField } from '@material-ui/core';


/**
 * @typedef {import("../../../../types/models").Company} Company
 */

/**
 * @typedef {{
 *   company: Company;
 * }} ICompanyDetailsProps
 */

/** @type {React.FC<ICompanyDetailsProps>} */
const CompanyDetails = ({ company }) => {
    return company ? <>
        <TextField helperText=" " variant='filled' value={company.address} label={'Address'} fullWidth disabled />
        <p style={{ marginTop: 4 }} />
        <TextField variant='filled' value={company.city} label={'City'} disabled fullWidth />
        <p style={{ marginTop: 4 }} />
        <TextField variant='filled' value={company.zip} type='number' label={'Zip'} disabled />
        <p style={{ marginTop: 4 }} />
        <TextField variant='filled' value={company.contactName} label={'Contact name'} fullWidth disabled />
        <p style={{ marginTop: 4 }} />
        <TextField variant='filled' value={company.contactEmail} label={'Email'} fullWidth disabled />
        <p style={{ marginTop: 4 }} />
        <TextField variant='filled' value={company.contactPhone} label={'Phone'} disabled />
        <p style={{ marginTop: 4 }} />
    </> : null;
};

export default CompanyDetails;
