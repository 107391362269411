import moment from "moment";
import {proposalServices} from "../../../services/proposal";
import jsonExport from "jsonexport";
import {downloadCSV} from "react-admin";

export const mountEmployeesPrice = (data) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const employeesPriceArray = [];
  for(let i = 0; i < data.length; i+=1) {
    employeesPriceArray[data[i].planName] = data[i]?.employeesPrice.map((item) => {
      let price = 0;
      price += +(item.spousePrice || 0) + +(item.price || 0);
      price += item.childrenPrice?.reduce((acc, child) => acc + +child.price, 0.0);
      return price?formatter.format(price):'n/a';
    });
  }

  return employeesPriceArray;
}

export const removePlans = [ 'Vision', 'Dental', 'Life & LTD' ]

export const renderDate = (birthdate, age, effectiveDate) => {
  if (!birthdate && !age) return 'na'
  if (birthdate) {
    const ageByBirth = moment(effectiveDate).diff(birthdate, 'years')
    return `${moment(birthdate).format('MMM D, Y')} (${ageByBirth})`
  } else return age
}

export const handleExcel = async record => {
  const data = await proposalServices.getEmployeesPrice(record.id);
  const pricesExport =[]

  const getPriceByPlanAndEmployee = (plan, index) =>
    data.find(planItem => planItem.planName === plan.name).employeesPrice[index]

  record.employeeCensus.forEach((employee, index)=>{
    let addPrice = {
      "Company Name": record.company.name,
      "Company City": record.company.city,
      "Company Email": record.company.contactEmail,
      "Company Contact name": record.company.contactName,
      "Company Phone": record.company.contactPhone,
      "Company ZIP": record.company.zip,
      Name: employee.name,
      Relation: 'Employee',
      ZIP: employee.zip,
      DOB: renderDate(employee.birthdate, employee.age, record.effectiveDate),
    }
    record.plans.forEach(plan=> {
      if (!removePlans.includes(plan.name))
        addPrice[plan.name] = getPriceByPlanAndEmployee(plan, index).price
    })
    pricesExport.push(addPrice)
    if(employee.spouseAge || employee.spouseBirthdate){
      addPrice = {
        Name: employee.name,
        Relation: 'Spouse',
        ZIP: employee.zip,
        DOB: renderDate(employee.spouseBirthdate, employee.spouseAge, record.effectiveDate),
      }
      record.plans.forEach(plan=> {
        if (!removePlans.includes(plan.name))
          addPrice[plan.name] = getPriceByPlanAndEmployee(plan, index).spousePrice
      })
      pricesExport.push(addPrice)
    }
    if(employee.children?.length){
      employee.children.forEach((child, childIndex)=>{
        addPrice = {
          Name: employee.name,
          Relation: 'Child',
          ZIP: employee.zip,
          DOB: renderDate(child.birthdate, child.age, record.effectiveDate),
        }
        record.plans.forEach(plan=> {
          if (!removePlans.includes(plan.name))
            addPrice[plan.name] = getPriceByPlanAndEmployee(plan, index).childrenPrice[childIndex].price
        })
        pricesExport.push(addPrice)
      })
    }
  })

  jsonExport(pricesExport, {}, (err, csv) => {
    if (!err)
      downloadCSV(csv, record.company.name)
  })
}

export const checkLevel = (value, form) => {
  if(!value?.length) return 'Employee census is required'
  if(value?.length > 1 && form?.groupLevel === 'm1') return 'To add more than 1 employee you must select the group level M2'
}