import moment from "moment";
import { useRecordContext } from "ra-core";

export const cleanEmpty = obj => {
  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
      .filter(v => !(v === null || v === ''));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
      .reduce((a, [k, v]) => ((v === null || v === '') ? a : (a[k]=v, a)), {});
  }
}

export const RecordDateStringField = (props) => {
  const { field } = props;
  const record = useRecordContext();

  return (<span>{moment(record[field]).format("MMM DD, YYYY")}</span>);
}

export const TotalCensusCount = () => {
  const record = useRecordContext();
  const employee = record.employeeCensus.length;
  const dependants = record.employeeCensus.reduce((acc, item) => {
    if(item.spouseAge || item.spouseBirthdate) {
      acc+=1;
    }
    if(item.children?.length) {
      acc+=item.children?.length;
    }

    return acc;
  }, 0);

  return (
    <div style={{fontSize: 10}}>
      <span>Employee: {employee}</span>
      <br/>
      <span>Dependants: {dependants}</span>
      <br />
      <span>Total: {employee + dependants}</span>
    </div>
  );
} 

export const FullNameWithEmailField = () => {
  const record = useRecordContext();

  return (
    <>
      <span>{record.user?.fullName}</span>
      <br/>
      <span style={{fontSize: 10}}>{record.updatedBy?.email}</span>
    </>
  );
};

export const LastModifiedField = () => {
  const record = useRecordContext();

  return (
    <>
      <span>{moment(record.updatedAt).format("MM/DD/YYYY")}</span>
      <br/>
      <span style={{fontSize: 10}}>{record.updatedBy?.email}</span>
    </>
  );
};

export const CreatedByField = () => {
  const record = useRecordContext();

  return (
    <>
      <span>{moment(record.createdAt).format("MM/DD/YYYY")}</span>
      <br/>
      <span style={{fontSize: 10}}>{record.createdBy?.email}</span>
    </>
  );
};

export const validateDate = (value) => {
  if(new Date(value) > new Date()) {
    return 'This date can\'t be in the future';
  }

  return undefined;
}

export const validateEmail = (value) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  return !emailRegex.test(value)
    ? 'Insert a valid email'
    : undefined;
}

export const validateAge = (value) => {
  return value < 0 && 'Insert a valid age';
}

export const downloadByURL = (url, fileName) => {
  const a = document.createElement('a')
  a.href = url
  a.download = `${fileName}.pdf`
  a.click()
  a.remove()
  setTimeout(() => window.URL.revokeObjectURL(url), 100)  
}

export const formatDate = (dateStr) => {
   if(!dateStr) return dateStr
  
  const date = moment(dateStr, ['YYYY/MM/DD', 'YYYY-MM-DD', 'MM/DD/YYYY']).locale('en');
  return date.format('YYYY-MM-DD');
}